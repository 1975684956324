<template>
  <div class="withDrawApplication">
    <!-- element ui的dialog弹窗 -->
    <el-dialog title="退库申请" :visible.sync="agreeApplication.isShow" :append-to-body="false"  :close-on-click-modal="false">
      <el-form>
        <el-form-item label="退库原因">
          <template>
            <!-- element ui 的 单选框 -->
            <el-radio v-model="delForm.radio" label="1">设备损坏，产品有瑕疵</el-radio>
            <el-radio v-model="delForm.radio" label="2">订单已被取消</el-radio>
            <el-radio v-model="delForm.radio" label="3">信息录入错误</el-radio>
            <el-radio v-model="delForm.radio" label="4">产品错发</el-radio>
            <el-radio v-model="delForm.radio" label="5">其他</el-radio>
            <!-- element ui 的 文本域 -->
            <el-input type="textarea" placeholder="请输入退库原因" v-model="delForm.textarea" maxlength="200" show-word-limit>
            </el-input>
          </template>
        </el-form-item>
      </el-form>
      <!-- 确定按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="quitDepot">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { delNetINform } from "@/utils/api";
import { Message } from "element-ui";
export default {
  props: ['agreeApplication','informs'],
  inject:['grandData'],
  data () {
    return {
      delForm:{
        radio: "1",
        textarea: '',
      },
      timer:null,//定时器名称
    };
  },

  mounted () {
    // console.log(this.$parent.total);
  },

  methods: {
    // 退库申请
    quitDepot(){
      delNetINform(this.informs).then(res =>{
          if(res.data.code == 1){
            Message.success(res.data.msg);
            this.agreeApplication.isShow = false
          }else{
            Message.warning(res.data.msg);
            this.timer = setTimeout(()=>{
              this.agreeApplication.isShow = false
            },1500)
            return
          }
          // 刷新数据
          this.grandData.getAlist()
        }).catch(err => {
          Message.warning(err)
        })
      
    }
    
    
  },
};
</script>

<style scoped>
::v-deep .el-dialog__title {
  color: #171717;
  font-family: "SHSCN_Bold";
  line-height: 18px;
}
.el-form /deep/ .el-form-item__label::before {
  content: "*";
  color: #df2626;
}
.el-form /deep/ .el-form-item__label {
  font-family: "SHSCN_Regular";
  margin: 1.9vh 0 0 3.65vw;
}
::v-deep .el-form-item__content{
  display: flex;
  flex-direction: column;
}
/* 每一个单选框的上下距离 */
.el-form-item__content .el-radio{
  margin: 3.61vh 0 0 2.22vw;
}
/* 文本域 */
.el-textarea{
  width: 24.22vw;
  height: 12.62vh;
  margin: 3vh 0 0 3.5vw;
}
.el-textarea /deep/ .el-textarea__inner{
  min-height: 12.6vh !important;
  resize: none;
}
</style>
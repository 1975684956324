<template>
  <div class="multShip">
    <!-- 批量入库 -->
    <!-- Element ui的dialog弹窗 -->
    <el-dialog title="批量入库" :visible.sync="multship.isShow" :close-on-click-modal="false" @close='closeDialog'>
      <!-- Element ui的文件上传 -->
      <el-upload class="upload-demo" drag multiple :on-change="upLoadFile"
        action="https://api.fediot.net/ele/admin/net/inpAll" :auto-upload='false' :show-file-list="isTrue"
        webkitdirectory :limit="1">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传Excel文件，且不超过2M</div>
      </el-upload>
      <!-- 确定按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchShip">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入消息提示
import { msg } from "../../../common/public";
// 引入批量入库api
import { multShipping } from "../../../utils/api";
import { Message } from "element-ui";
export default {
  props: ['multship'],
  data () {
    return {
      net: '',
      isTrue: true,//是否展示上传文件的列表
      timer: null,//定时器
    };
  },
  mounted () {
  },

  methods: {
    // dialog的关闭
    closeDialog () {
      this.net = '';
      this.isTrue = false
    },
    // 文件上传
    upLoadFile (file) {
      if (!file.raw) { //文件不存在时
        return
      } else {
        this.isTrue = true
        let { size, type } = file.raw;
        // 1.判断文件大小
        if (size > 2 * 1024 * 1024) { //Excel文件大小不得超过2M
          msg('文件大小不能超过2M', 'warning');
          return false;
        }
        //判断文件格式是否为excel文件
        let typeArr = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 'application/vnd.ms-excel']
        if (typeArr.indexOf(type) == -1) {
          msg('只能上传Excel文件', 'warning');
          return false;
        }
        this.net = file.raw;
      }
    },
    // 批量入库
    batchShip () {
      multShipping({ net: this.net }, true).then(res => {
        if (res.data.code == 1) {
          Message.success(res.data.msg);
        } else {
          Message.warning(res.data.msg)
        }
        // 刷新数据
        this.$parent.getAlist()
        this.timer = setTimeout(() => {
          this.multship.isShow = false;
        }, 1000)
      }).catch(err => {
        return console.log(err);
      })
    },
  },
  destroyed () {
    // 消除定时器
    clearTimeout(this.timer)
  }
};
</script>

<style scoped>
::v-deep .el-dialog__title {
  color: #171717;
  font-family: "SHSCN_Bold";
  line-height: 18px;
}

.el-dialog__body .upload-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<template>
  <!-- 网关管理 -->
  <div class="gateWayWareHoushimg">
    <!-- 板块中心区域 -->
    <div class="gateway_mid">
      <div class="gateway_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="">
          <p v-for="(item, index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 网关编号 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">主体</span>
              <el-select v-model="params.gid" placeholder="请选择主体" style="margin:0 1.45vw 0 0" clearable filterable
                allow-create>
                <el-option v-for="item in gidList" :key="item.id" :label="item.gname" :value="item.id">
                </el-option>
              </el-select>
            </template>
            <!-- 是否激活 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">网关状态</span>
              <el-select v-model="params.status" placeholder="请选择状态">
                <el-option v-for="item in isStatus" :key="item.id" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
            <!-- 按地址搜索 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">网关地址</span>
              <el-input v-model="params.stxt" placeholder="请输入网关地址"></el-input>
            </template>
          </div>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="gateway_form">
        <!-- 列表页 -->
        <lists :obj="obj" :allData="allData" @getPage="getPage" @getSize="getSize" :size="size" @editNet="editNet" :loading="loading">
        </lists>
      </div>
      <!-- 表单页 -->
      <!-- <forms :obj="obj" :aNetInfor="aNetInfor" ref="editInform"></forms> -->
      <!-- 注册批量入库 -->
      <!-- <multship :multship="multship" :aNetInfor="aNetInfor"></multship> -->
    </div>
  </div>
</template>

<script>
// 引入表格区域和表单区域
import forms from "./components/forms.vue";
import lists from "./components/lists.vue";
// 引入批量入库的dialog
import multship from "./components/multship.vue";

// 获取网关入库--网关列表
import { gateWayList, accountManagementList } from '../../utils/api'

export default {
  components: {
    forms,//表单页
    lists,//列表页
    multship,//批量入库
  },
  provide() {
    return {
      grandData: this
    }
  },
  data() {
    return {
      net: '',//网关编号
      value2: '',//供货商
      tableData: [],//表单数据
      allData: '',//所有数据
      page: 1,//页码
      size: 10,//每页显示的数据条数
      aNetInfor: '',//一条网关数据信息
      status: 1,//状态：0未激活，1激活
      isStatus: [
        { id: 1, label: '全部', value: 'all' },
        { id: 2, label: '离线', value: 'offline' },
        { id: 3, label: '在线', value: 'online' }
      ],
      options: [],
      params: {
        page: 1,
        size: 10,
        stxt: '',
        status: 'all',
        gid: ''
      },
      gidList: [],
      loading: true,
      obj: false
    };
  },

  created() {
    this.getGidList()
    this.getAlist()
  },
  watch: {
    params: {
      handler: function (val) {
        this.getAlist()
      },
      deep: true
    }
  },
  methods: {
    // 修改网关信息
    editNet(netInform) {
      let tag = true;//只有点击编辑时才会有标识
      netInform = { ...netInform, tag }
      this.aNetInfor = netInform;
      this.$refs.editInform.isDis = true
    },
    // 网关入库
    addNet() {
      this.obj.isShow = true
      this.$refs.editInform.isDis = false
    },
    // 获取当前数据的总页数
    getSize(size) {
      this.params.page = 1
      this.params.size = size;
    },
    // 获取当前页
    getPage(page) {
      this.params.page = page
    },
    // 获取网关列表
    getAlist() {
      this.loading = true
      gateWayList(this.params).then(res => {
        if (res.code == 1) {
          // 查询数据成功
          this.allData = res.data
          // 将查询的数据存储到localstorage
          localStorage.setItem('gateList', JSON.stringify(this.allData))
        }
        this.loading = false
      }).catch(err => {
        return console.log(err);
      })
    },
    getGidList () {
      accountManagementList({}).then(res => {
        if (res.code == 1) {
          res.data.data.unshift({id: '', gname: '全部'})
          this.gidList = res.data.data;
        }
      }).catch(err => {
        return console.log(err);
      })
    },
  },
};
</script>

<style scoped>
.gateWayWareHoushimg {
  height: 100%;
  margin: -30px 0 0 0;
}

.gateWayWareHoushimg .gateway_mid {
  height: 100%;
}

.gateway_mid .gateway_header {
  min-height: 104px;
  width: 100%;
  background-color: #fff;
}

.gateway_header .title {
  display: flex;
  align-items: flex-end;
}

.gateway_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}

.gateway_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}

/* 选项框和按钮 */
.option_btn {
  width: 100%;
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option_btn /deep/ .el-input {
  width: 206px;
  height: 32px;
}

.options {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.option_btn /deep/ .el-input__icon {
  line-height: 1.85vh;
}

.option_btn span {
  font-size: 16px;
  font-family: "SHSCN_Regular";
}

.btns {
  margin-top: 26px;
}

/* 头部--结束 */

/* 表格区域--开始 */
.gateway_mid .gateway_form {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 1vh;
}

.lists /deep/ .el-pagination {
  align-items: normal;
  margin-top: 14px;
  height: 50px !important;
}
</style>
<template>
  <!-- 网关入库--表单页 -->
  <div class="forms">
    <!-- Element ui -- Dialog对话框 -->
    <el-dialog :title="aNetInfor.tag == true ? '网关修改':'网关入库' " :visible.sync="obj.isShow" :close-on-click-modal="false"
      @close='closeDialog'>
      <el-form :model="form" ref="form">
        <!-- 型号 -->
        <el-form-item label="型号" :label-width="formLabelWidth" class="xinghao">
          <el-select v-model="form.model" placeholder="请选择网关型号" style="width:100%;height:32px">
            <el-option :label="item.label" :value="item.value" v-for="item in models" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 通信方式 -->
        <el-form-item label="通信方式" :label-width="formLabelWidth" class="acMethod">
          <el-select v-model="form.type" placeholder="请选择通讯方式" style="width:100%;height:32px">
            <el-option :label="item.label" :value="item.value" v-for="item in acModthArr" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 采购日期 -->
        <el-form-item label="采购日期" :label-width="formLabelWidth" class="purchase">
          <el-date-picker v-model="purchaseData" type="datetime" placeholder="选择日期时间" style="width:100%;height:32px"
            disabled>
          </el-date-picker>
        </el-form-item>
        <!-- 供货商 -->
        <el-form-item label="供货商" :label-width="formLabelWidth" class="gonghuoshang">
          <el-input v-model="form.supplier" autocomplete="off" style="width:100%;height:32px"></el-input>
        </el-form-item>
        <!-- 网关编号 -->
        <el-form-item label="网关编号" :label-width="formLabelWidth" class="gateNumbering">
          <el-input v-model="form.nid" autocomplete="off" placeholder="请输入网关编号" style="width:100%;" @blur="checkGateNum"
            :disabled="isDis"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="aNetInfor.tag == true">
          <el-button type="primary" @click="editaNet" style="background-color: #0666F5;">修 改</el-button>
        </div>
        <div v-else>
          <el-button @click="resetForm()">重 置</el-button>
          <el-button type="primary" @click="gateWayStorage" style="background-color: #0666F5;">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { inputGateWay, editGateWagInform } from "../../../utils/api";
export default {
  props: ["obj", 'aNetInfor'],
  data () {
    return {
      curid: '',//判断是修改还是网关入库
      formLabelWidth: '100px',
      gateWayArr: [],
      purchaseData: '',//采购日期
      form: {
        model: '',//型号
        type: '',//通讯方式
        supplier: '',//供货商
        nid: '',//网关编号
      },
      timer: null,
      isDis: false,//编辑状态下，网关编号不能修改
    };
  },
  computed: {
    ...mapState('gateWayWareHousing', ["multDeice", 'models', 'acModthArr'])
  },
  mounted () {

  },
  updated () {
    this.getNewTime();
    this.restore()
  },
  methods: {
    // 修改网关信息
    editaNet () {
      this.isDis = true
      if (!this.form.type) {
        Message.error('通讯方式不为空')
        return false
      } else if (!this.form.card) {
        this.form.card = '1';//该值为物联网卡，后续会更改
      } else if (!this.form.model) {
        Message.error('网关型号不能为空')
        return false
      } else if (!this.form.supplier) {
        Message.error('供应商名称不能为空')
        return false
      }
      editGateWagInform(this.form).then(res => {
        if (res.data.code == 1) {
          Message.success(res.data.msg)
          timer = setTimeout(() => {
            this.obj.isShow = false;
          }, 1500)
        } else {
          Message.warning('数据更新一致，无需更新')
        }
        this.$parent.getAlist()
      }).catch(err => {
        return console.log(err);
      })
    },
    // 将获取到的一条数据还原到修改页面
    restore () {
      if (this.aNetInfor.tag) {
        this.form = this.aNetInfor
      } else {
        return false
      }
    },
    // el-dialog的关闭事件
    closeDialog () {
      this.resetForm();
    },
    checkGateNum () {
      // 判断网关编号是否正确
      let gateNumReg = /^(([a-zA-Z0-9]){8}-([a-zA-Z0-9]){8}-([a-zA-Z0-9]){8})$/;
      if (!gateNumReg.test(this.form.nid)) {
        Message.error('网关编号格式不正确,请重新输入');
        return false;
      }
    },
    // 网关入库 --  确定
    gateWayStorage () {
      let gateNumReg = /^(([a-zA-Z0-9]){8}-([a-zA-Z0-9]){8}-([a-zA-Z0-9]){8})$/;
      // 置空验证
      if (!this.form.model) {
        Message.error('网关型号不能为空');
        return false;
      } else if (!this.form.type) {
        Message.error('通信方式不能为空');
        return false;
      } else if (!this.form.supplier) {
        Message.error('供货商不能为空');
        return false;
      } else if (!this.form.nid) {
        Message.error('网关编号不能为空');
        return false;
      } else if (!gateNumReg.test(this.form.nid)) {
        Message.error('网关编号格式不正确,请重新输入');
        return false;
      }
      // 请求接口
      inputGateWay(this.form).then(res => {
        if (res.data.code == 1) {
          // 入库成功
          Message.success(res.data.msg);
          this.timer = setTimeout(() => {
            this.resetForm()
            this.obj.isShow = false;
          }, 2000)
          // 重置表单
        } else if (res.data.code == 2) {
          // 网关编号已存在
          Message.warning(res.data.msg)
        } else {
          // 网关编号错误/缺失或者其他数据缺失
          Message.error(res.data.msg)
        }
        this.$parent.getAlist()
      }).catch(err => {
        return console.log(err);
      });
    },

    // 获取当前时间并赋值给采购日期
    getNewTime () {
      let date = new Date();
      let year = date.getFullYear();
      let month = parseInt(date.getMonth()) + 1;
      let day = date.getDate();
      let hours = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
      let minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();
      let seconds = date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds();
      let time = `${year}-${month <= 9 ? '0' + month : month}-${day <= 9 ? '0' + day : day} ${hours}:${minutes}:${seconds}`;
      this.purchaseData = time
    },

    // 重置表单
    resetForm () {
      this.curid = '';
      for (let i in this.form) {
        this.form[i] = ''
      }
    },
    destroyed () {
      // 清除定时器
      clearTimeout(this.timer)
    },


  },
};
</script>

<style  scoped>
/* .forms {
  width: 0.4vw;
  height: 0.453vh;
} */
.forms /deep/ .el-dialog {
  margin-top: 15vh !important;
}
.el-form {
  display: grid;
  grid-template-columns: 50% 50%;
  font-family: "SHSCN_Regular";
}
/* 按钮外部的尺寸 */
.el-form-item /deep/ .el-button {
  font-family: "SHSCN_Regular";
}
/* 多个网关添加 */
.el-form-item .mult_gateWayAdd {
  width: 32vw;
  height: 18.1vh;
  border: 1px solid #c1c3c7;
  border-radius: 5px 5px 5px 5px;
  margin-left: 5.2vw;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 25% 25% 25%;
}
.el-form-item .mult_tip {
  font-size: 12px;
  font-family: "SHSCN_Regular";
  color: #707070;
  margin-left: 5.2vw;
}

/* 型号 */
.xinghao /deep/ .el-form-item__label {
  width: 75px !important;
}
/* 供货商的label */
.gonghuoshang /deep/ .el-form-item__label {
  width: 86px !important;
}
/* 多个网关的添加与删除 */

.gateWayCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
/* 多媒体查询 */
/* 大于1920 */
@media screen and (min-width: 1920px) {
  /* 添加按钮 */
  .button_box /deep/ .el-button {
    margin-left: -83px !important;
  }
}
/* 大于1600 小于1920 */
@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .gateWayCon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
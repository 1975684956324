<template>
  <!-- 网关管理-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table v-loading="loading" :data="tableData" style="width: 100%" :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#4888FF'}" :height="tableHeight" ref="multipleTable">
        <!-- 索引序号 -->
        <el-table-column type="index" min-width="100" fixed></el-table-column>
        <!-- 网关编号 -->
        <el-table-column prop="nid" label="网关编号" min-width="240" class="nid">
        </el-table-column>
        <!-- 型号 -->
        <el-table-column prop="model" label="型号">
          <template v-slot="{row}">{{ $netSeries(row.nid) }}</template>
        </el-table-column>
        <!-- 通信方式 -->
        <el-table-column prop="type" label="通信方式" min-width="80">
          <template slot-scope="scope">
            <p v-if="scope.row.type == '1' ">4G</p>
            <p v-else-if="scope.row.type == '0' ">WiFi</p>
            <p v-else>其他</p>
          </template>
        </el-table-column>
        <!-- 采购日期 -->
        <!-- <el-table-column prop="purchaseData" label="采购日期" min-width="200">
        </el-table-column> -->
        <!-- 供货商 -->
        <!-- <el-table-column prop="supplier" label="供货商" min-width="250">
        </el-table-column> -->
        <!-- 入库时间 -->
        <el-table-column prop="nname" label="网关名称" sortable min-width="250">
        </el-table-column>
        <el-table-column prop="naddr" label="网关地址" sortable min-width="250">
        </el-table-column>
        <el-table-column prop="intime" label="入库时间" sortable min-width="160">
        </el-table-column>
        <!-- 启用时间 -->
        <el-table-column prop="uptime" label="启用时间" min-width="160">
        </el-table-column>
        <!-- 退库 -->
        <!-- <el-table-column label="操作" fixed="right" width="150"> -->
          <!-- <template slot-scope="scope">
            <el-link type="primary" style="margin-right:8px" @click="edits(scope.row)">编辑</el-link> -->
            <!-- 未激活能删除 激活了不能删除 -->
            <!-- <el-link type="primary" @click="deleteNet(scope.row)" v-show="scope.row.status == 0">删除</el-link> -->
            <!-- <el-link type="primary" @click="deleteNet(scope.row)">退库</el-link> -->
          <!-- </template>
        </el-table-column> -->
        <div slot="empty" style="text-align: left;">
          <el-empty description="没有更多信息哦, 请尝试更换条件吧~" />
        </div>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="getCurPage" @size-change="handleSizeChange" :current-page.sync="page"
        :page-sizes="aSize" :page-size="size" layout="total, prev, pager, next,sizes,jumper" :total="total">
      </el-pagination>
    </template>
    <!-- 注册退库申请表单 -->
    <withdrawapplication :agreeApplication="agreeApplication" ref="withDraw" :informs='informs'></withdrawapplication>
  </div>
</template>

<script>
// 引入退库申请表单
import withdrawapplication from "./withDrawApplication.vue";
import { delNetINform } from "@/utils/api";

import { MessageBox, Message } from 'element-ui';
export default {
  props: ['allData', 'size', 'obj', 'loading'],
  components: {
    withdrawapplication,//退库
  },

  data () {
    return {
      page: 1,//页数
      tableData: [],//表单数据
      total: 0,//总数据
      aSize: [10, 20, 30, 50],
      agreeApplication: {
        isShow: false
      },
      informs: '',
      tableHeight :0,
      load: true
    };
  },
  watch: {
    allData (newVal, oldVal) {
      this.tableData = newVal.data;
      this.total = newVal.total
      // this.$nextTick(()=>{
      //   this.$refs.multipleTable.layout()
      // }
    }
  },
  created() {
    this.tableHeight = document.documentElement.clientHeight - 270;
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = document.documentElement.clientHeight - 270;
    })
  },
  methods: {
    // 删除网关信息
    deleteNet (informs) {
      console.log(informs);
      // 退库的操作 先隐藏  后来启用
      // this.informs = informs;
      // this.agreeApplication.isShow = true
      // this.$parent.getAlist()
      // 删除网关 需要id 和 nid
      delNetINform({ id: informs.id, nid: informs.nid }).then(res => {
        if (res.data.code == 1) {
          Message.success(res.data.msg)
        } else {
          Message.warning(res.data.msg)
        }
        this.$parent.getAlist()
      }).catch(err => {
        return console.log(err)
      })

    },
    // 编辑
    edits (netInform) {
      if (netInform.status) {//网关已激活，可以进行修改
        this.obj.isShow = true
        this.$emit('editNet', netInform)
      } else {//网关未激活，不能修改
        MessageBox({
          type: 'error',
          message: '网关尚未激活，不能修改',
          closeOnClickModal: false
        })
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 1) {
        return 'success-row';
      }
      return '';
    },
    // 分页的方法
    handleSizeChange (val) {
      this.$emit('getSize', val)
    },
    //获取当前页的页码
    getCurPage (val) {
      this.$emit('getPage', val)
    }
  },
};
</script>

<style  scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-weight: normal;
  font-size: 14px;
}
.lists {
  position: relative;
  height: 100%;
}
.lists .el-pagination {
  position: absolute;
  height: 60px;
  bottom: 80px;
  right: 0;
  background-color: #fff;
  font-family: "Source Han Sans CN-Regular";
}
.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}
.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}

</style>